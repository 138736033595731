import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaMinus, FaPlus } from 'react-icons/fa';
import Similarproduct from '../medicines/Similarproduct';
import { useDispatch, useSelector } from 'react-redux';
import { getOneProduct } from '../../slice/productSlice';
import { addProduct } from '../../slice/cartSlice';

const Medicinesdetails = () => {
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOneProduct(id));
  }, [dispatch, id]);

  const data = useSelector((store) => store.product);
  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncrease = () => {
    setQuantity(quantity + 1);
  };

  const handleAddToCart = () => {
    dispatch(addProduct({ ...data[0], qty: quantity }));
    navigate('/cart'); // Redirect to the cart page
  };

  return (
    <>
      <section className="py-lg-5 my-5">
        <div className="container">
          <div className="row leftspace" data-aos="fade-up" >
            <aside className="col-lg-6">
              <div className="d-flex ">
                <img 
                  className="d-block medidetailproduct mb-2" loading='lazy'
                  src={process.env.REACT_APP_BACKEND_URL_REL + data[0]?.image}
                  alt={data[0]?.name}
                />
              </div>
            </aside>
            <main className="col-lg-6">
              <div className="ps-lg-3">
                <h4 className="medicinetitle">
                  {data[0]?.name}
                </h4>
                <div className="d-flex flex-row my-3">
                </div>
                <div className="mb-3">
                  <span className="rs">Rs {data[0]?.sprice}/- </span>
                  <span className="instoke">{data[0]?.quantity ? 'In Stock' : 'Out of stock'}</span>
                </div>
                <p className='medidetailtext'>
                  {data[0]?.long_description}
                </p>
                <hr className='pdetailshr mt-5' />
                <div className="row mb-3">
                  <div className="col-md-8 col-7 d-flex align-items-center">
                    <label className="mr-2">Quantity</label>
                    <div className="input-group bottlesize" style={{ width: '110px' }}>
                      <button
                        className="btn text-white mr-2"
                        type="button"
                        onClick={handleDecrease}
                        style={{ width: '30px' }}
                      >
                        <FaMinus />
                      </button>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={quantity}
                        readOnly
                      />
                      <button
                        className="btn text-white mr-2"
                        type="button"
                        onClick={handleIncrease}
                        style={{ width: '30px' }}
                      >
                        <FaPlus />
                      </button>
                    </div>
                  </div>
                  <div className='col-lg-4 col-5 d-flex align-items-center'>
                    <button
                      onClick={handleAddToCart}
                      className="py-lg-2 py-2 px-lg-4 px-2 border-0 rounded mr-lg-3 addtocart text-decoration-none"
                    >
                      Add to cart
                    </button>
                  </div>
                </div>
                <hr className='pdetailshr mb-5' />
              </div>
            </main>
          </div>
        </div>
      </section>
      <Similarproduct />
    </>
  );
};

export default Medicinesdetails;
