import React from 'react'
import heroimage from '../Assets/images/teambanner.png'
const Teambanner = () => {
  return (
    <div>
          <div className="card  text-white border-0">
                <img className="card-img" src={heroimage} alt="Card image" />
                <div className="card-img-overlay d-flex align-items-center justify-content-center">
                </div>
            </div>
    </div>
  )
}

export default Teambanner
