import React from 'react';
import s1 from '../Assets/images/fb1.png';
import s2 from '../Assets/images/fb2.png';
import s3 from '../Assets/images/fb3.png';
import s4 from '../Assets/images/insta111.png';
import s5 from '../Assets/images/inst2.png';
import s6 from '../Assets/images/insta3.png';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

const Socailmedia = () => {
  const products = [
    {
      id: 1,
      name: 'Abhyangam Therapy',
      imgUrl: s1,
      facebookLink: 'https://www.facebook.com/photo/?fbid=1067423898719836&set=pb.100063567085114.-2207520000',
      aosEffect: 'fade-up', // This is the AOS effect
      aosDelay: '100'       // This is the delay in milliseconds
    },
    {
      id: 2,
      name: 'Marma Therapy',
      imgUrl: s2,
      facebookLink: 'https://www.facebook.com/Drpathakscure/videos/1956027161527473',
      aosEffect: 'fade-up', // This is the AOS effect
      aosDelay: '200'       // This is the delay in milliseconds
    },
    {
      id: 3,
      name: 'Vibrator Marma Therapy',
      imgUrl: s3,
      facebookLink: 'https://www.facebook.com/Drpathakscure/videos/358962810485363',
      aosEffect: 'fade-up', // This is the AOS effect
      aosDelay: '300'       // This is the delay in milliseconds
    },
    {
      id: 4,
      name: 'Combo Marma Therapy',
      imgUrl: s4,
      instagramLink: 'https://www.instagram.com/p/C96nRBfvrX7/',
      aosEffect: 'fade-up', // This is the AOS effect
      aosDelay: '400'       // This is the delay in milliseconds
    },

    {
      id: 5,
      name: 'Shirodhara Therapy',
      imgUrl: s5,
      instagramLink: 'https://www.instagram.com/p/C_NRiROo_RQ/',
      aosEffect: 'fade-up', // This is the AOS effect
      aosDelay: '500'       // This is the delay in milliseconds
    },
    {
      id: 6,
      name: 'Vasti Karma Therapy',
      imgUrl: s6,
      instagramLink: 'https://www.instagram.com/p/C9SNLKAvaWX/',
      aosEffect: 'fade-up', // This is the AOS effect
      aosDelay: '600'       // This is the delay in milliseconds
    },
  ];

  return (
    <div>
      <div className='py-5'>
        <div className='container pt-5'>
          <div className="container section-title text-center" data-aos="fade-up">
            <h2 className='text-center aboutheading'>Media and News</h2>
            <h6 className='text-center pt-2 pb-5'>
              Stay Informed with the Latest News and Media
            </h6>
          </div>
          {/* Facebook Section */}
          <div className='row pt-lg-5 leftspace'>
            {products.slice(0, 3).map(product => (
              <div key={product.id} className="col-md-4 mb-5">
                <div className="services text-center">
                  <div className="position-relative" 
                  data-aos={product.aosEffect}
                  data-aos-delay={product.aosDelay}>
                    <a href={product.facebookLink} target="_blank" rel="noopener noreferrer">
                      <img
                        className="product-image img-fluid servicescard shadow"
                        src={product.imgUrl}
                        alt={product.name}
                        style={{ borderRadius: '0' }}
                      />
                    </a>
                    <div className="icon-overlay socialmediaicons position-absolute" style={{ top: '10px', right: '10px' }}>
                      <a href={product.facebookLink} target="_blank" rel="noopener noreferrer">
                        <FaFacebook size={30} className='socailmediaicons' />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Instagram Section */}
          <div className='row pb-5 leftspace'>
            {products.slice(3, 6).map(product => (
              <div key={product.id} className="col-md-4 mb-5">
                <div className="services text-center">
                  <div className="position-relative"
                  data-aos={product.aosEffect}
                  data-aos-delay={product.aosDelay}>
                    <a href={product.instagramLink} target="_blank" rel="noopener noreferrer">
                      <img
                        className="product-image w-100 servicescard shadow"
                        src={product.imgUrl}
                        alt={product.name}
                        style={{ borderRadius: '0' }}
                      />
                    </a>
                    <div className="icon-overlay socialmediaicons position-absolute" style={{ top: '10px', right: '10px' }}>
                      <a href={product.instagramLink} target="_blank" rel="noopener noreferrer">
                        <FaInstagram size={30} className='socailmediaicons' />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Socailmedia;
