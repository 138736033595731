import React, { useRef, useState, useEffect } from 'react';
import html2pdf from 'html2pdf.js';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import opdlogo from '../../Assets/images/dphclogo.png'
import { FaPhoneAlt, FaEnvelope, FaGlobe } from 'react-icons/fa';
import { } from '../../slice/productSlice';
import { getRequest } from '../../apis/ApiInstence'

function Confirm() {
    const contentRef = useRef(null);
    const [appointmentData, setAppointmentData] = useState(null)
    const user = useSelector(store => store.user);
    const params = useParams();
    const [currentDate, setCurrentDate] = useState('');



    useEffect(() => {
       getRequest( 'appointments/one/' + params?.id).then((data)=>{
            setAppointmentData(data?.data)
        })
        const today = new Date();
        const formattedDate = today.toLocaleDateString('en-GB');
        setCurrentDate(formattedDate);
    }, []);



    // getRequest('')

    const printFile = () => {
        const options = {
            filename: 'my-document.pdf',
            margin: 1,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        const content = contentRef.current;
        html2pdf().set(options).from(content).save();
    }

    return (
        <>
            <div className='my-5'>
                <div className='container p-0' ref={contentRef}>
                    <div className='row  p-0'>
                        <div className='col-lg-4 col-md-4 col-4 text-left'>
                            <img src={opdlogo} alt='logo' className='img-fluid opdlogo' width={150} />
                        </div>
                        <div className='col-lg-4 col-md-6 col-8  pt-3 text-lg-center'>
                            <h5 className='pb-lg-1 opdmainh'>Dr Pathaks Holistic Cure</h5>
                            <h6 className='opdsubh'>Out Patient Department</h6>
                            <p className='eopd'>e-OPD Card</p>
                        </div>
                        <div className='col-lg-4 col-12  pt-3 text-lg-right'>
                            <h5 className='opdmainh' id='apt' >Appointment Type :</h5>
                            <p id='apt'>{appointmentData?.appointment_type}</p>
                        </div>
                    </div>
                    <div className='row p-0 mt-4 justify-content-space-between ' >
                        <div className='col-lg-8 col-md-6 col-12 order-lg-1 order-2'  >
                            <h5 className='opdmainh'>Appointment ID : <span className='opdspan'>101001</span></h5>
                            <h5 className='opdmainh'>Appointment Date & Time : <span className='opdspan'>{appointmentData?.appointment_date}</span> <span className='opdspan'>{appointmentData?.appointment_time}</span></h5>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 order-lg-2 order-1 text-lg-right'>
                            <h5 className='opdmainh' id='datelopd'>Date :</h5>
                            <p id='datelopd'>{currentDate}</p>
                            <h5 className='opdmainh' id='datesopd'>Date : <span className='opdspan'>{currentDate}</span></h5>
                        </div>
                    </div>

                    <div className='row p-0 mt-4 justify-content-space-between'>
                        <div className='col-lg-6'>
                            <h5 className='opdmainh' id='datesopd'>Appointment Type : <span className='opdspan'>{appointmentData?.appointment_type}</span></h5>
                            <h5 className='opdmainh'>Nearest District or City: <span className='opdspan'>{appointmentData?.city}</span></h5>
                            <h5 className='opdmainh'>Details of Illness/Condition : <span className='opdspan'>{appointmentData?.illness}</span></h5>
                        </div>
                        <div className='col-lg-6 col-12  text-lg-right'>
                            <h5 className='opdmainh' id='datelopd'>Reporting Time :</h5>
                            <p id='datelopd'>10 minutes Before</p>
                            <h5 className='opdmainh' id='datesopd'>Reporting Time : <span className='opdspan'>10 minutes Before</span></h5>
                        </div>
                    </div>
                    <div className="row p-0 mt-4 mb-5 ">
                        <div className='table-responsive'>
                            <table className="table table-bordered  ">
                                <tbody>
                                    <tr>
                                        <td><strong>Patient Name : </strong></td>
                                        <td>{appointmentData?.name}</td>
                                        <td><strong>Age :</strong></td>
                                        <td>{appointmentData?.age}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Contact Detail : </strong></td>
                                        <td><strong>Phone : </strong><span> {appointmentData?.patient_mobile}</span></td>
                                        <td><strong>Address:</strong></td>
                                        <td>{appointmentData?.address}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Doctor Name:</strong></td>
                                        <td>{appointmentData?.assign_doctor}</td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='mt-5 pt-5'>
                        <div className='row  mt-5 pt-5 text-center'>
                            <h6 className='aptp'>We look forward to providing you with the best care.
                            </h6>
                            <p className="aptpcontact ">
                                <span className="mr-4 ">
                                    <FaPhoneAlt className="mr-lg-2" /> 8516064764, 7566064764
                                </span>
                                <span className="mr-4">
                                    <FaEnvelope className="mr-2" /> yogidrraghav@gmail.com
                                </span>
                                <span>
                                    <FaGlobe className="mr-2" /> www.drpathaksholisticcure.com
                                </span>
                            </p>
                            <p className="pt-3 text-center aptp aptpcontactm">
                                <span className="">
                                    <FaPhoneAlt className="mr-1" /> 8516064764, 7566064764
                                </span>
                            </p>
                            <p className="aptp text-center aptpcontactm">
                                <span className="">
                                    <FaEnvelope className="mr-1" /> yogidrraghav@gmail.com
                                </span>
                            </p>
                            <p className=" aptp  text-center aptpcontactm">
                                <span className=" ">
                                    <FaGlobe className="mr-1" /> www.drpathaksholisticcure.com
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='text-center my-3'>
                    <button className='text-decoration-none productbtn font-weight-bold btn-lg' onClick={() => printFile()}>
                        <span className='fa fa-file mx-2'></span>PRINT
                    </button>
                </div>
            </div>
        </>
    )
}

export default Confirm