import React, { useEffect, useState, Suspense } from 'react'


import Quickinfo from '../../components/Quickinfo'
import Socailmedia from '../../components/Socailmedia'
import Appointment from '../../pages/appiontment/Make_Appointment'
import HowWeWork from '../../components/HowWeWork'
import CounterSection from '../../components/CounterSection'
import Feedback from '../../components/Feedback'
import Mainservices from '../services/Mainservices'
import Faq from '../../components/Faq'
import Contctus from '../../pages/contact/Contactus'
import Medicinehome from '../../pages/medicines/Medicinehome'
import Doctors from '../drpathakji/Doctors';
import axios from 'axios';
import './home.css';

const LazyComponent = React.lazy(() => import('../../components/Slider'));


const Home = () => {

  const [homeData, setHomeData] = useState({})

  const getHome = () => {
    axios.get(process.env.REACT_APP_BACKEND_URL + '/home').then((data) => {
      if (data) {
        setHomeData(data.data)
      }
    })
  }
  useEffect(() => {
    getHome()
  }, [])

  return (
    <div>
      <Suspense fallback={<div class="loading-card">
        <div class="loading-image"></div>
        <div class="loading-content">
          <div class="loading-line"></div>
          <div class="loading-line short"></div>
        </div>
      </div>}>
        <LazyComponent images={homeData.banner_data} />
      </Suspense>
      <Quickinfo />
      <Mainservices />
      <Doctors />
      <Appointment />
      <Medicinehome product_data={homeData.product_data?.length ? homeData.product_data : []} />
      <HowWeWork />
      <Socailmedia />
      <CounterSection />
      <Faq />
      <Feedback />
      <Contctus />
    </div>
  )
}

export default Home
