import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    user: null,
    response: null,
    loading: false,
    error: null,
};

export const orderSlice = createSlice({
    name: 'orderSlice',
    initialState,
    reducers: {
        success: (state, { payload }) => {
            state.loading = false
            state.response = payload
            return state
        },
        failed: (state) => {
            state.loading = false
            state.response = null
        },
        placeorder: (state, { payload }) => {
            state.loading = true
        },
        getAllOrderDetails: (state) => {
            state.loading = true
        },
        getOrderDetail:(state)=>{
            state.loading = true
        }
    },
})

// Action creators are generated for each case reducer function
export const { success, failed, placeorder, getAllOrderDetails, getOrderDetail } = orderSlice.actions

export default orderSlice.reducer