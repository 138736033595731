import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/home/Home';
import Medicines from './pages/medicines/Medicines';
import Medicinesdetails from './pages/medicines/Medicinesdetails';
import About from './pages/about/About';
import Ourteam from './pages/teams/Ourteam';
import Contact from './pages/contact/Contact';
import Login from './pages/user/Login';
import Register from './pages/user/Register';
import Cart from './pages/cart/Cart';
import Services from './pages/services/Services';
import Checkout from './pages/chekout/Checkout';
import PhotoGallery from './pages/media/PhotoGallery';
import MediaNews from './pages/media/MediaNews';
import Doctordetails from './pages/drpathakji/Doctordetails';
import ScrollToTop from './components/ScrollToTop';
import Myprofile from './pages/user/Myprofile';
import Myorder from './pages/user/Myorder';
import Stickyicon from './components/Stickyicon';
import PrivateRouter from './routers/PrivateRouter';
import Payment from './pages/chekout/Payment';
import Make_Appointment from './pages/appiontment/Make_Appointment';
import RenderRazorpay from './components/payment/RenderRazorpay';
import Otp from './pages/user/Otp';
import MyorderDetails from './pages/user/MyorderDetails';
import Confirm from './pages/payment/Confirm';
import Aos from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/medicines" element={<Medicines />} />
        <Route path="/medicinesdetails/:id" element={<Medicinesdetails />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/ourteam" element={<Ourteam />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/photogallery" element={<PhotoGallery />} />
        <Route path="/medianews" element={<MediaNews />} />
        <Route path="/doctordetails" element={<Doctordetails />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/online-payment" element={<RenderRazorpay />} />
        <Route path="/makeappointment" element={<Make_Appointment />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/receipt/:id" element={<Confirm />} />

        {/* Private Routes */}
        <Route element={<PrivateRouter />}>
          <Route path="/myorder" element={<Myorder />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/myorder/:id" element={<MyorderDetails />} />
          <Route path="/myprofile" element={<Myprofile />} />
        </Route>

        {/* Catch-all route */}
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
      <Stickyicon />
    </Router>
  );
};

export default App;
