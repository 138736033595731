import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  response: null,
  loading: false,
  error: null,
  callTimes: 0,
  message: '',
  isLogin: false
};



const userData = localStorage.getItem('user') !== 'undefined' && localStorage.getItem('user') !== undefined ? localStorage.getItem('user') : null;
if (userData && userData !== undefined) {
  initialState.response = userData ? JSON.parse(userData) : {};
  initialState.token = userData?.data?.access_token;
  initialState.isLogin = true
} else {
  initialState.response = null;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signup: (state, { payload }) => {
      state.loading = true;
      state.callTimes++;
    },
    signin: (state, { payload }) => {
      state.loading = true;
      state.callTimes++;
    },
    failed: (state, { payload }) => {
      console.log(payload)
      state.error = false;
      state.loading = false;
      state.message = payload?.data?.message;
      state.response = []
      return state;
    },

    getoneuser: (state, { payload }) => {
      state.callTimes++;
    }, 

    successLogin: (state, { payload }) => {
      localStorage.setItem('user', JSON.stringify(payload?.data))
      localStorage.setItem('token', payload?.data?.access_token)
      state.loading = false;
      state.response = payload?.data;
      state.message = payload.message;
      state.token = payload?.data?.access_token;
      state.error = null;
      state.isLogin = true;
    },
    success: (state, { payload }) => {
      state.loading = false;
      state.response = payload?.data;
      state.message = payload.message;
      state.token = payload?.data?.access_token;
      state.error = null;
      return state;
    },
    logout: (state) => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      state.response = [];
      state.loading = false;
      state.message = 'Logout Successfully';
      state.isLogin = false;
    },
    otpverification: (state)=>{
      state.loading = true;
    }
  },
})

// Action creators are generated for each case reducer function
export const { signup, signin, success, failed, logout, otpverification, successLogin , getoneuser } = userSlice.actions

export default userSlice.reducer