import React, { useState } from 'react';
import border from '../../Assets/images/border2.png';
import { useDispatch, useSelector } from 'react-redux';
import { addAppointment, failed } from '../../slice/appointmentSlice';
import CommonAlert from '../../common/CommonAlert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import RenderRazorpay from '../../components/payment/RenderRazorpay';
import { getorderId } from '../../slice/paymentSlice';


const Make_Appointment = () => {

  const dispatch = useDispatch()
  const nevigate = useNavigate()
  const [formSubmit, setFormSubmit] = useState(false)
  const [paymentOption, setPaymentOption] = useState(false)
  const data = useSelector(store => store.appointment)
  const order_data = useSelector(store => store.payment)

  const [formData, setFormData] = useState({
    name: '',
    age: '',
    illness: '',
    previous_treatment: '',
    address: '',
    city: '',
    assign_doctor: '',
    appointment_date: '',
    appointment_time: '',
    appointment_type: '',
    patient_mobile: '',
    patient_mobile_whatsapp: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'file' ? e.target.files[0] : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmit(true)
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    if (formData?.appointment_type === 'online') {
      setPaymentOption(true)
      setFormData(formDataToSend)
      dispatch(getorderId({
        amount: 250,
        currency: 'INR'
      }))
    } else {
      try {
        dispatch(addAppointment(formData))
        setFormData({
          name: '',
          age: '',
          illness: '',
          previous_treatment: '',
          address: '',
          city: '',
          assign_doctor: '',
          appointment_date: '',
          appointment_time: '',
          appointment_type: '',
          patient_mobile: '',
          patient_mobile_whatsapp: '',
          message: '',
        })
        //  window.location.href =`/receipt/${data?.id}`
      } catch (error) {
        console.error('There was a problem adding the product:', error.message);
      }
    }
    // document.getElementById("appoitment_form").reset();
  };

  const onConfirm = () => {
    nevigate(`/receipt/${data?.response?.id}`)
    dispatch(failed())
  }

  // const [selectedDate, setSelectedDate] = useState(new Date());

  // Get today's date
  // const today = new Date();


  return (
    <div>
      {
        data.response?.id && <CommonAlert
          title="Appointment Book Successfully"
          text="We will contact you soon"
          icon="success"
          confirmButtonText="Ok"
          onConfirm={onConfirm}
        />
      }
      {paymentOption && order_data?.response?.length && <RenderRazorpay
        orderId={order_data?.response?.[0]?.order_id}
        keyId={'rzp_test_jL3UplbraK01a5'}
        keySecret={'0rbAAXWTiONv2UY37kiQgQmU'}
        currency={'INR'}
        amount={order_data?.response?.[0]?.amount}
        name={formData}
        setPaymentOption={setPaymentOption}
      />}

      <div className='makeandappointmentbg' id='appointment'>
        <section id="appointment" className="appointment section">
          <div className="container section-title text-center" data-aos="fade-up">
            <h3 className='text-center appointmentheading  pt-5 pb-2'>Appointment</h3>
            <h6 className='text-center pb-2'>Book an appointment for personalized therapy and care.{formData.name}</h6>
            <img src={border} className="img-fluid pb-5" alt='border' width={200} />
          </div>
          <div className="container appointmentinputrow" >
            <form className="php-email-form" onSubmit={handleSubmit} id="appoitment_form">
              <div className="row">
                <div className="col-md-4 form-group">
                  <label htmlFor="name">Patient's Name</label>
                  <input type="text" name="name" defaultValue={formData.name} onChange={handleChange} className="form-control" id="name" required />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="age">Patient's Age</label>
                  <input type="number" className="form-control" defaultValue={formData.age} name="age" onChange={handleChange} id="age" required />
                </div>
                <div className="col-md-4 form-group ">
                  <label htmlFor="service">Details of Illness/Condition</label>
                  <input type="text" className="form-control" name="illness" defaultValue={formData.illness} onChange={handleChange} id="service" required />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="previous_treatment">Ongoing Treatment, if any?<span className='ml-2'>(Optional)</span></label>
                  <input type="text" name="previous_treatment" defaultValue={formData.previous_treatment} onChange={handleChange} className="form-control" id="treatment" />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="address">Patient's Address</label>
                  <input type="text" className="form-control" name="address" defaultValue={formData.address} onChange={handleChange} />
                </div>
                <div className="col-md-4 form-group">
                  <label htmlFor="doctor">Nearest District or City</label>
                  <select name="city" id="city" onChange={handleChange} defaultValue={formData.city} className="form-select custom-select" required>
                    <option value="">Select City</option>
                    <option value="Khajuraho">Khajuraho</option>
                    <option value="Chhatarpur">Chhatarpur</option>
                    <option value="Jabalpur">Jabalpur</option>
                    <option value="Rewa">Rewa</option>
                    <option value="Pendra">Pendra</option>
                    <option value="Narsinghpur">Narsinghpur</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group mt-3">
                  <label htmlFor="doctor">Medical Specialist</label>
                  <select name="assign_doctor" onChange={handleChange} defaultValue={formData.assign_doctor} id="doctor" className="form-select custom-select">
                    <option value="">Select Doctor</option>
                    <option value="Dr.Raghav Pathak">Dr.Raghav Pathak</option>
                    <option value="Dr.Rina Mudiya">Dr.Rina Mudiya</option>
                  </select>
                </div>
                <div className="col-md-2 form-group mt-3">
                  <label htmlFor="date">Appointment Date</label>
                  <input type="date" name="appointment_date" defaultValue={formData.appointment_date} onChange={handleChange} className="form-control datepicker" id="date" required="" />
                </div>
                <div className="col-md-2 form-group mt-3">
                  <label htmlFor="time">Appointment Time</label>
                  <select
                    name="appointment_time"
                    onChange={handleChange}
                    id="service"
                    className="form-select custom-select"
                    required
                    defaultValue={formData.appointment_time}
                  >
                    <option value="">Select Time</option>
                    <option value="3:00 PM - 3:15 PM">3:00 PM - 3:15 PM</option>
                    <option value="3:15 PM - 3:30 PM">3:15 PM - 3:30 PM</option>
                    <option value="3:30 PM - 3:45 PM">3:30 PM - 3:45 PM</option>
                    <option value="3:45 PM - 4:00 PM">3:45 PM - 4:00 PM</option>
                    <option value="4:00 PM - 4:15 PM">4:00 PM - 4:15 PM</option>
                    <option value="4:15 PM - 4:30 PM">4:15 PM - 4:30 PM</option>
                    <option value="4:30 PM - 4:45 PM">4:30 PM - 4:45 PM</option>
                    <option value="4:45 PM - 5:00 PM">4:45 PM - 5:00 PM</option>
                    <option value="5:00 PM - 5:15 PM">5:00 PM - 5:15 PM</option>
                    <option value="5:15 PM - 5:30 PM">5:15 PM - 5:30 PM</option>
                    <option value="5:30 PM - 5:45 PM">5:30 PM - 5:45 PM</option>
                    <option value="5:45 PM - 6:00 PM">5:45 PM - 6:00 PM</option>
                    <option value="6:00 PM - 6:15 PM">6:00 PM - 6:15 PM</option>
                    <option value="6:15 PM - 6:30 PM">6:15 PM - 6:30 PM</option>
                    <option value="6:30 PM - 6:45 PM">6:30 PM - 6:45 PM</option>
                    <option value="6:45 PM - 7:00 PM">6:45 PM - 7:00 PM</option>
                  </select>
                </div>
                <div className="col-md-4 form-group mt-3">
                  <label htmlFor="doctor">Appointment Type</label>
                  <select name="appointment_type" defaultValue={formData.appointment_type} onChange={handleChange} id="" className="form-select custom-select" required>
                    <option value="">Select type</option>
                    <option value="online">Online</option>
                    <option value="offline">Offline</option>
                  </select>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="name">Patient's or caretaker's phone number</label>
                  <input type="number" name="patient_mobile"
                    maxLength={10}
                    minLength={10}
                    onChange={handleChange} className="form-control"
                    defaultValue={formData.patient_mobile}
                    id="phonenumber"
                    pattern="\d{10}"
                    title="Please enter a 10-digit Phone number"
                    required />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="email">Patient's or caretaker's WhatsApp number (Optional) </label>
                  <input type="number" className="form-control"
                    name="patient_mobile_whatsapp"
                    maxLength={10}
                    minLength={10}
                    defaultValue={formData.patient_mobile_whatsapp}
                    onChange={handleChange} id="whatsappnumber"
                    pattern="\d{10}"
                    title="Please enter a 10-digit WhatsApp number"
                  />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="file">Upload any extra information patient reports/X-rays</label>
                  <label className="custom-file-upload" htmlFor="file">
                    Choose File
                  </label>
                  <input type="file" name="image" id="file" onChange={handleChange} className="form-control-file" />
                </div>

              </div>
              <div className="form-group mt-3">
                <label htmlFor="message">Message (Optional)</label>
                <textarea className="form-control" name="message" onChange={handleChange} id="message" rows="3" defaultValue={formData.message}></textarea>
              </div>
              <div className="mt-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your appointment request has been sent successfully. Thank you!</div>
                <div className="text-center py-5" >
                  <button type="submit" className='makeappointmentbtn' disabled={formSubmit}>Make an Appointment</button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Make_Appointment;
