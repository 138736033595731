import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderDetail } from '../../slice/orderSlice';
import html2pdf from 'html2pdf.js';



const MyorderDetails = () => {
  const data = useSelector(cart => cart.order);
  const user = useSelector(store => store.user);
  const params = useParams();
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const calculateTotal = (items) => {
    return items.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  useEffect(() => {
    dispatch(getOrderDetail(params?.id))
  }, [dispatch])


  const printFile = () => {
    const options = {
      filename: 'my-document.pdf',
      margin: 1,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    const content = contentRef.current;
    html2pdf().set(options).from(content).save();
  }

  return (
    <div className="container my-5">
      <h2 className="pb-5 text-center aboutheading">Order History</h2>
      <div class="page-content container" ref={contentRef}>
        <div class="page-header text-blue-d2">
          <h1 class="page-title text-secondary-d1">
            Invoice
            <small class="page-info">
              <i class="fa fa-angle-double-right text-80"></i>
              ID: #{data?.response?.order?.order_id}
            </small>
          </h1>

          <div class="page-tools">
            <div class="action-buttons">
              <button class="btn bg-white btn-light mx-1px text-95" onClick={() => printFile()}>
                <i class="mr-1 fa fa-print text-primary-m1 text-120 w-2"></i>
                Print
              </button>
            </div>
          </div>
        </div>

        <div class="container px-0">
          <div class="row mt-4">
            <div class="col-12 col-lg-12">
              <hr class="row brc-default-l1 mx-n1 mb-4" />
              <div class="row">
                <div class="col-sm-6">
                  <div>
                    <span class="text-sm text-grey-m2 align-middle">To:</span>
                    <span class="text-600 text-110 text-blue align-middle text-capitalize"> {data?.response?.customerData?.name}</span>
                  </div>
                  <div class="text-grey-m2">
                    <div class="my-1">
                      {data?.response?.customerData?.address}
                    </div>
                    <div class="my-1"><i class="fa fa-phone fa-flip-horizontal text-secondary"></i> <b class="text-600">{data?.response?.customerData?.mobile}</b></div>
                  </div>
                </div>
              </div>

              <div class="mt-4">
                <div class="row text-600 bgc-default-tp1 py-25">
                  <div class="d-none d-sm-block col-1">#</div>
                  <div class="col-9 col-sm-5">Description</div>
                  <div class="d-none d-sm-block col-4 col-sm-2">Qty</div>
                  <div class="d-none d-sm-block col-sm-2">Unit Price</div>
                  <div class="col-2">Amount</div>
                </div>

                <div class="text-95 text-secondary-d3">


                  {data?.response?.itemData1?.map((item) => {
                    return <div class="row mb-2 mb-sm-0 py-25">
                      <div class="d-none d-sm-block col-1">1</div>
                      <div class="col-9 col-sm-5">{item?.product?.name}</div>
                      <div class="d-none d-sm-block col-2">{item.quantity}</div>
                      <div class="d-none d-sm-block col-2 text-95">{item.price}</div>
                      <div class="col-2 text-secondary-d2">{item?.subtotal}</div>
                    </div>
                  })}
                </div>
                <div class="row mt-3">
                  <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                    Extra note such as company or payment information...
                  </div>
                  <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                    <div class="row my-2">
                      <div class="col-7 text-right">
                        SubTotal
                      </div>
                      <div class="col-5">
                        <span class="text-110 text-secondary-d1">{data?.response?.itemData1 && calculateTotal(data?.response?.itemData1)}</span>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-7 text-right">
                        Tax (10%)
                      </div>
                      <div class="col-5">
                        <span class="text-110 text-secondary-d1">0</span>
                      </div>
                    </div>

                    <div class="row my-2 align-items-center bgc-primary-l3 p-2">
                      <div class="col-7 text-right">
                        Total Amount
                      </div>
                      <div class="col-5">
                        <span class="text-150 text-success-d3 opacity-2">{data?.response?.itemData1 && calculateTotal(data?.response?.itemData1)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-5 pb-5">
        <Link to="/" className="checkout text-decoration-none">
          Continue Shopping
        </Link>
      </div>
    </div>
  );
};

export default MyorderDetails;