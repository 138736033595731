import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { IoLogoYoutube, IoCall, IoCart } from "react-icons/io5";
import { IoMdMenu } from "react-icons/io";
import logo from '../Assets/images/logo.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../slice/userSlice';

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
        document.body.classList.toggle('no-scroll', !isMobileMenuOpen);
    };

   
    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
        document.body.classList.remove('no-scroll');
    };

    const cartData = useSelector((store) => store.cart);
    const userData = useSelector((store) => store.user);
    const dispatch = useDispatch();

    return (
        <div>
            <marquee style={{ background: 'red', padding: '5px' }}>
                <b style={{ color: 'white' }}>Our website is currently under construction. We're working hard to give you a better experience, and we'll be up and running soon!</b>
            </marquee>
            <header id="header" className="header sticky-top">
                <div className="topbar d-flex align-items-center">
                    <div className="container d-flex justify-content-md-center justify-content-md-between">
                        <div className="contact-info d-flex align-items-center text-white">
                            <a href="mailto:yogidrraghav@gmail.com" className="text-decoration-none text-white ml-lg-1">
                                yogidrraghav@gmail.com
                            </a>
                            <IoCall className="d-flex align-items-center ml-3" />
                            <a href="tel:+8516064764" className="text-decoration-none text-white ml-1">
                                8516064764
                            </a>
                            <a href="tel:+7566064764" className="text-decoration-none text-white ml-3">
                                7566064764
                            </a>
                        </div>

                        <div className="social-links d-none d-md-flex align-items-center mr-lg-2">
                            <a href="https://twitter.com/PathakCure20979" className="twitter text-white" target="_blank" rel="noopener noreferrer">
                                <FaTwitter />
                            </a>
                            <a href="https://www.facebook.com/Drpathakscure" className="facebook text-white" target="_blank" rel="noopener noreferrer">
                                <FaFacebook />
                            </a>
                            <a href="https://www.instagram.com/drpathaksholisticcure" className="instagram text-white" target="_blank" rel="noopener noreferrer">
                                <FaInstagram />
                            </a>
                            <a href="https://youtube.com/@drpathaksholisticcure?si=QVlXl9WnK0GsvKvg" className="youtube text-white" target="_blank" rel="noopener noreferrer">
                                <IoLogoYoutube />
                            </a>
                            <a href="https://wa.me/917748018010" className="whatsapp text-white" target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="branding d-flex align-items-center">
                    <div className="container position-relative d-flex align-items-center justify-content-between">
                        <Link to="/" className="logo d-flex align-items-center me-auto" onClick={closeMobileMenu}>
                            <img src={logo} alt='drpathaks' className='img-fluid logo' />
                        </Link>
                        <nav id="navmenu" className={`navmenu ${isMobileMenuOpen ? 'open' : ''}`}>
                            <ul>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/" onClick={closeMobileMenu}>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/services" onClick={closeMobileMenu}>Services</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/medicines" onClick={closeMobileMenu}>Medicines</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/aboutus" onClick={closeMobileMenu}>About</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/ourteam" onClick={closeMobileMenu}>Our Team</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle mymenu d-flex align-items-center" to="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded={false}>
                                        Media
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link className="dropdown-item pl-3" to="/photogallery" onClick={closeMobileMenu}>Photo Gallery</Link></li>
                                        <li><Link className="dropdown-item pl-3" to="/medianews" onClick={closeMobileMenu}>Media & News</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/contact" onClick={closeMobileMenu}>Contact Us</Link>
                                </li>
                                {userData && userData?.isLogin ? (
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle mymenu d-flex align-items-center" to="#" id="navbarDropdownMenuLinkUser" role="button" data-bs-toggle="dropdown" aria-expanded={true}>
                                            User
                                        </Link>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLinkUser">
                                            <li><Link className="dropdown-item pl-3" to="/myorder" onClick={closeMobileMenu}>My Order</Link></li>
                                            <li><Link className="dropdown-item pl-3" to="/myprofile" onClick={closeMobileMenu}>My Profile</Link></li>
                                            <li><button className="dropdown-item pl-3 bg-danger" onClick={() => { closeMobileMenu(); dispatch(logout()); }}>Logout</button></li>
                                        </ul>
                                    </li>
                                ) : (
                                    <li className="nav-item">
                                        <Link className="nav-link mymenu" to="/login" onClick={closeMobileMenu}>Login</Link>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/cart" onClick={closeMobileMenu}>
                                        Cart<IoCart className='ml-2' size={20} /><span>{cartData.length}</span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        <IoMdMenu className="mobile-nav-toggle d-xl-none" onClick={toggleMobileMenu} role="button" aria-label="Toggle mobile menu" />
                        <Link className="cta-btn d-none d-sm-block ml-5 text-decoration-none" to="/makeappointment">Make an Appointment</Link>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Header;
